import React from "react";
import Header from "./Header/Header";
import Footer from "./footer";
import { useEffect } from "react";
import "../App.css";
function Tech() {
    useEffect(() => {
        if (
            !document.querySelector("header").classList.contains("headerStick")
        ) {
            document.querySelector("header").classList.add("headerStick");
        }
        window.scroll(0, 0);
    }, []);

    return (
        <div className="AppArt con" style={{ alignItems: "center" }}>
            <Header />
            <div className="techinner techw" style={{ flex: "1 0 auto" }}>
                <h1
                    style={{
                        fontSize: "3.5em",
                        fontFamily: "r-regular",
                        fontWeight: "lighter",
                    }}
                >
                    Syed Ali
                </h1>
                <h5
                    style={{
                        fontSize: "1em",
                        fontFamily: "r-regular",
                        fontWeight: "lighter",
                    }}
                >
                    {new Date().getFullYear() - 2001}, Bangalore, India
                </h5>
                <h3
                    className="r-regular"
                    style={{ marginTop: "1em", marginBottom: "0.3em" }}
                >
                    Bachelor of Computer Science Engineering,
                    <br />
                    <div
                        style={{
                            fontSize: "0.75em",
                            fontFamily: "r-regular",
                            fontWeight: "lighter",
                        }}
                    >
                        Ramaiah University, Bangalore, 2020-2024
                    </div>
                </h3>
                {/* underline */}
                <h3
                    style={{
                        marginTop: "1em",
                        marginBottom: "0em",
                        fontSize: "2.5em",
                        fontFamily: "r-regular",
                    }}
                >
                    Experience
                </h3>

                <p className="techtext r-medium">
                    <ul>
                        <li>
                            <div className="dur">
                                Music Technology Researcher & ML Developer at{" "}
                                <a
                                    href="https://beatoven.ai"
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    beatoven.ai
                                </a>
                                , <br />
                                <div
                                    style={{
                                        fontSize: "0.75em",
                                        fontFamily: "r-regular",
                                        fontWeight: "lighter",
                                    }}
                                >
                                    June, 2021 - Present,{" "}
                                    {new Date().getFullYear() - 2021} years
                                </div>
                            </div>
                            <div
                                className="jd"
                                style={{
                                    fontSize: "0.65em",
                                    textTransform: "capitalize",
                                }}
                            >
                                Music Information Retreival, Machine Learning,
                                Anomaly Detection, Music & SFX library
                                management, & Plugin Development for music
                                production.
                                <br />
                            </div>
                        </li>
                        <li>
                            <div className="dur">
                                Musician, Qareebi
                                <br />
                                <div
                                    style={{
                                        fontSize: "0.75em",
                                        fontFamily: "r-regular",
                                        fontWeight: "lighter",
                                    }}
                                >
                                    December, 2018 - Present,{" "}
                                    {new Date().getFullYear() - 2018} years
                                </div>
                            </div>
                            <div className="jd" style={{ fontSize: "0.65em" }}>
                                As Qareebi, Electronic & Ambient <br />
                                Previously as IO (2018 - 2020)
                            </div>
                        </li>
                        <li>
                            <div className="dur">
                                Multimedia Designer, Independent <br />
                                <div
                                    style={{
                                        fontSize: "0.75em",
                                        fontFamily: "r-regular",
                                        fontWeight: "lighter",
                                    }}
                                >
                                    August, 2019 - Present,{" "}
                                    {new Date().getFullYear() - 2019} years
                                </div>
                            </div>
                            <div className="jd" style={{ fontSize: "0.65em" }}>
                                Print & Digital Multimedia, Colour Management
                                for Print, Typography, Album Arts, Branding &
                                Identity, <br />
                                Adobe CC Suite - Photoshop, Illustrator, After
                                Effects & InDesign.
                            </div>
                        </li>
                    </ul>
                </p>
                <p></p>
            </div>
            <Footer />
        </div>
    );
}

export default Tech;
