import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Portfolio from './Components/portfolio';
import Music from './Components/music';
import Tech from './Components/tech';
import About from './Components/contact';
import './App.css';

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Portfolio} />
        <Route path="/music" exact component={Music} />
        <Route path="/tech" exact component={Tech} />
        <Route path="/contact" exact component={About} />
        <Route path="/about" exact component={About} />
        {/* redirect presskit to a google drive folder */}
        <Route path="/presskit" exact component={() => {
          window.location.href = 'https://drive.google.com/drive/folders/1ubrd2C77G2PD-jTuQKduoR2Hek1Gfu1W?usp=sharing';
          return null;
        }} />
        <Route path="/linkedin" exact component={() => {
          window.location.href = 'https://www.linkedin.com/in/basisvectors/';
          return null;
        }} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
