import "../App.css";
import React from "react";

import up from "../assets/up.svg";
// import vid1 from "../assets/111.mp4"
// import vid2 from "../assets/222.mp4"
// import vid3 from "../assets/33.mp4"

import Header from "./Header/Header";
import Footer from "./footer";

import { useHasScrolled } from "./useScroll";
import { useEffect } from "react";

function Portfolio() {
  const hasScrolled = useHasScrolled();
  useEffect(() => {
    window.scrollTo(0, 22);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="AppArt">
      <Header />

      <div className="content">
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CszChAzA20t/"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted>
              <source src="https://i.imgur.com/2T1b33e.mp4" type="video/mp4" />
            </video>
          </a>
        </div>

        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CC3HfpcpOvW/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/BJD0OII.jpg"
              alt="Tape casette design"
            />
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CC3IyxOpeY3/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/LnBnzis.jpg"
              alt="Tape casette design"
            />
          </a>
        </div>

        <div className="artImg">
          <a
            href="https://www.instagram.com/p/Cah6oyhgHW3/"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted>
              <source src="https://i.imgur.com/XDtg1iF.mp4" type="video/mp4" />
            </video>
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CI_EowRpXaq/"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted>
              <source src="https://i.imgur.com/8pzdXmQ.mp4" type="video/mp4" />
            </video>
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CI-hq2vpgCn/"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted>
              <source src="https://i.imgur.com/06iJhkU.mp4" type="video/mp4" />
            </video>
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CI-Qredp7tm/"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted>
              <source src="https://i.imgur.com/B4hFVGU.mp4" type="video/mp4" />
            </video>
          </a>
        </div>

        <div className="artImg">
          <a
            href="https://www.instagram.com/p/Cge_XAGvJ-p/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/xNoqTVg.png"
              alt="John James Audubon, Baudrillard, Poster A4"
            />
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CDWE-LUJwyN/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/SgLokcX.png"
              alt="Gil Scott Heron, Poster A4"
            />
          </a>
        </div>
        <div className="artImg">
          <a
            href="https://www.instagram.com/p/CDWEBbKpf7G/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imgur.com/nBdF0cj.png"
              alt="Gil Scott Heron, Poster A4"
            />
          </a>
        </div>

        {/* POSTERS */}

        {/*  */}

        <button
          className="up"
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          <img src={up} width="24px" height="auto" alt="up" />
        </button>
        <Footer />
      </div>
    </div>
  );
}

export default Portfolio;
