import React from "react";
import instagram from "../assets/ig.png";

function Footer() {
    return (
        <div className="footer">
            {/* soundcloud and bandcamp icons */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            >
                <a
                    href="https://instagram.com/qaree.bi"
                    target="_blank"
                    rel="noreferrer"
                    className="footerIcons"
                    style={{
                        marginRight: "20px",  
                        height: "30px",
                        width: "30px",
                    }}

                >
                    <img
                        src="https://img.icons8.com/ios/50/ffffff/instagram-new.png"
                        alt="instagram"
                        height={30}
                        width={30}
                    />
                </a>
                <a
                    href="https://soundcloud.com/qareebi"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footerIcons"
                    style={{ marginRight: "20px" }}

                >
                    <img
                        src="https://img.icons8.com/ios-glyphs/50/ffffff/soundcloud.png"
                        alt="soundcloud"
                    />
                </a>
                <a
                    href="https://qareebi.bandcamp.com/"
                    target="_blank"
                    className="footerIcons"
                    rel="noopener noreferrer"
                >
                    <img
                        src="https://img.icons8.com/ios-filled/50/ffffff/bandcamp.png"
                        alt="bandcamp"
                    />
                </a>
            </div>
            <h6
                style={{
                    color: "white",
                    fontWeight: "normal",
                }}
            >
                © {new Date().getFullYear()} syed ali,{" "}
                <a
                    href="https://www.instagram.com/qaree.bi/"
                    target="_blank"
                    rel="noreferrer"
                >
                    qaree.bi
                </a>
                <br />
            </h6>
        </div>
    );
}

export default Footer;
