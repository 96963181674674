import { useState, useEffect } from 'react';

export const useHasScrolled = (distance = 20) => {

    // setting initial value to false
    const [scroll, setScroll] = useState(false)

    // running on mount
    useEffect(() => {
        const onScroll = () => {
            // Logic is false tell user reaches threshold, then true after.
            const scrollCheck = window.scrollY >= distance;

            if (scrollCheck) {
                document.querySelector("header").classList.add("headerStick");
                // setScroll(scrollCheck)
                
            } else {
                document.querySelector("header").classList.remove("headerStick");
            }
        }

        // setting the event handler from web API
        document.addEventListener("scroll", onScroll)

        // cleaning up from the web API
        return () => {
            document.removeEventListener("scroll", onScroll)
        }

    }, [scroll, setScroll])

    return scroll
}