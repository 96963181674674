import "./header.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";

const Header = ({ mode }) => {
    return (
        <>
            <header className={"nav"}>
                <div className="Navlinks">
                    <div
                        className="navlinks"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ul>
                            <li>
                                <NavLink
                                    exact
                                    to="/"
                                    className="links r-black"
                                    activeClassName="active"
                                >
                                    art
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to="/music"
                                    className="links r-black"
                                    activeClassName="active"
                                >
                                    music
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/" className="logo">
                                    <img
                                        src={logo}
                                        alt="qareebi"
                                        className="logo"
                                    />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to="/tech"
                                    className="links r-black"
                                    activeClassName="active"
                                >
                                    tech
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to="/about"
                                    className="links r-black"
                                    activeClassName="active"
                                >
                                    about
                                </NavLink>
                            </li>
                            {/* <li>
              <NavLink to="/shop" className="links r-black" activeClassName="active" >
                shop
              </NavLink>
            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="toggledivq">
                    {/* <img
            src={logo}
            alt="menu"
            className="logo toggle"
            // width="full"
            // height="auto"
            
          /> */}
                    <ul>
                        <li>
                            <NavLink
                                exact
                                to="/"
                                className="links hide r-black"
                                activeClassName="active"
                            >
                                art
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact
                                to="/music"
                                className="links hide r-black"
                                activeClassName="active"
                            >
                                music
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/" className="logo">
                                <img
                                    src={logo}
                                    alt="qareebi"
                                    className="logo"
                                />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact
                                to="/tech"
                                className="links hide r-black"
                                activeClassName="active"
                            >
                                tech
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact
                                to="/about"
                                className="links hide r-black"
                                activeClassName="active"
                            >
                                about
                            </NavLink>
                        </li>
                        {/* <li>
              <NavLink to="/shop" className="links r-black" activeClassName="active" >
                shop
              </NavLink>
            </li> */}
                    </ul>
                </div>
            </header>
        </>
    );
};

export default Header;
